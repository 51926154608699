<template>
	<div>
		<div class="flex header">
			<h2>会员信息</h2>
			<div class="flex">
				<el-button @click="addMember" v-if=" user.permissions.indexOf('32') != -1">新增会员信息</el-button>
				<el-button @click="$refs.importFile.importFile=true">会员信息导入</el-button>
				<!--				<el-upload>会员信息导入</el-upload>-->
			</div>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="100px">
			<div class="flex mb-1  ">
				<el-form-item label="停车场">
					<el-select v-model="searchForm.parkId" placeholder="请选择停车场">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车牌号码">
					<el-input v-model="searchForm.carNo" placeholder="请输入车牌号码"></el-input>
				</el-form-item>
			</div>
			<div class="flex mb-1  ">
				<el-form-item label="公司名称">
					<el-input v-model="searchForm.ownerCompany" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="用户类型">
					<el-select v-model="searchForm.memberType" placeholder="请选择用户类型">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in memberTypeList"
											 :value="item.name"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex">
				<el-form-item label="有效期起始">
					<el-date-picker
							v-model="searchForm.startDate"
							type="date"
							placeholder="有效期起始">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="有效期结束">
					<el-date-picker
							v-model="searchForm.endDate"
							type="date"
							placeholder="有效期结束">
					</el-date-picker>
				</el-form-item>
				<div class=" btnGroup averageWid">
					<el-button @click="getData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<div class="table">
			<el-table
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 420px)"
					style="width: 100%">
				<el-table-column
						prop="carNo" align="center" show-overflow-tooltip
						label="车牌号码"
						width="100">
				</el-table-column>
				<el-table-column align="center"
												 label="有效期起始"
												 width="100">
					<template slot-scope="scope">
						{{ scope.row.startDate|formatDay }}
					</template>
				</el-table-column>
				<el-table-column align="center" width="100"
												 label="有效期结束">
					<template slot-scope="scope">
						{{ scope.row.endDate|formatDay }}
					</template>
				</el-table-column>
				<el-table-column
						prop="memberType" align="center"
						label="用户类型"
						width="150">
				</el-table-column>
				<el-table-column
						prop="carOwner" align="center"
						label="车主姓名" show-overflow-tooltip
						width="180">
				</el-table-column>
				<el-table-column
						prop="ownerCardNo" align="center" show-overflow-tooltip
						label="身份证号" width="180">
				</el-table-column>
				<el-table-column
						prop="ownerTel" align="center"
						label="电话号码" width="120">
				</el-table-column>
				<el-table-column
						prop="ownerCompany" align="center" show-overflow-tooltip
						label="公司名称">
				</el-table-column>
				<el-table-column align="center"
												 label="办理时间"
												 width="180">
					<template slot-scope="scope">
						{{ scope.row.applyDate|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column
						prop="chargeParking" align="center" show-overflow-tooltip
						label="收费停车场" width="180">
				</el-table-column>
				<el-table-column
						prop="remark" align="center" show-overflow-tooltip
						label="备注">
				</el-table-column>
				<el-table-column
						align="center" show-overflow-tooltip
						label="审核状态">
					<template slot-scope="scope">
						<span
								v-if="scope.row.source==1">{{ scope.row.approvalStatus == 0 ? "未审核" : scope.row.approvalStatus == 1 ? "待审核" : scope.row.approvalStatus == 2 ? "已审核" : "拒绝" }}</span>
						<span v-else>--</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center" show-overflow-tooltip
						label="来源">
					<template slot-scope="scope">
						{{ scope.row.source == 0 ? scope.row.createBy+"导入" : scope.row.createBy+"录入" }}
					</template>
				</el-table-column>
				<el-table-column
						prop="dataHandler"
						align="center" show-overflow-tooltip
						label="办理人">
				</el-table-column>
				<el-table-column align="center" fixed="right"
												 label="操作" width="260px">
					<template slot-scope="scope">
						<el-button class="update" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
						<el-button class="update"
											 v-if="scope.row.source==1 && scope.row.approvalStatus==0 && user.permissions.indexOf('33') == -1 "
											 @click="toApproval(scope.row,scope.$index)">未审核
						</el-button>
						<el-button class="update"
											 v-if="scope.row.source==1 &&  user.permissions.indexOf('33') != -1 && (scope.row.approvalStatus==0 || scope.row.approvalStatus==1)"
											 @click="approval(scope.row)">待审核
						</el-button>
						<el-button class="update" @click="doAddBlack(scope.row)">加入黑名单</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<!--		新增修改-->
		<updateMember @openMember="editMemInfo" :state="state" :showMember="showEditMem" :memberId="memberId"
									:memberTypeList="memberTypeList" :parkingList="parkingList"></updateMember>

		<!--		加入黑名单-->
		<el-dialog
				top="0" center class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="blacklist" :show-close="false"
				width="370px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">加入黑名单</h1>
			</template>
			<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px" :model="formBlack">
				<el-form-item label="车牌号码">
					<el-input :value="formBlack.carNo" placeholder="请输入车牌号码"></el-input>
				</el-form-item>
				<el-form-item label="级别">
					<el-select style="width:100%" v-model="formBlack.blackLevel" placeholder="请选择黑名单级别">
						<el-option :label="item.label" :key="ind" v-for="(item,ind) in blackLevelSel" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="formBlack.blackReason" type="textarea" rows="5" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="blacklist=false">取 消</el-button>
				<el-button type="primary" @click="addBlack">确 定</el-button>
			</div>
		</el-dialog>

		<!--会员审核-->
		<el-dialog
				top="0" center class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="approvalDialog" width="370px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">会员审核</h1>
			</template>
			<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px">
				<el-form-item label="车牌号码">
					<el-input :value="carNo" placeholder="请输入车牌号码"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="reasons" type="textarea" rows="5" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="submit(3)">拒绝</el-button>
				<el-button type="primary" @click="submit(2)">通过</el-button>
			</div>
		</el-dialog>

		<!--导入-->
		<importFile ref="importFile" :uploadUrl="uploadUrl" @reflush="reloadSearch"></importFile>
	</div>
</template>

<script>
import { getCarParkInfoList, getMemberInfoList, addToBlack } from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");

export default {
	name: "member",
	data() {
		return {
			uploadUrl: "/car/member-info/import",
			searchForm: {
				chargeParking: "",
				ownerCompany: "",
				memberType: "",
				startDate: "",
				endDate: "",
				parkId: ""
			},
			blackLevelSel: [{
				val: "Ⅰ级",
				label: "Ⅰ级"
			}, {
				val: "Ⅱ级",
				label: "Ⅱ级"
			}, {
				val: "Ⅲ级",
				label: "Ⅲ级"
			}],
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			rows: {},
			showEditMem: false,
			state: "新增",
			blacklist: false,
			formBlack: {
				carNo: "",
				blackLevel: "",
				blackReason: ""
			},
			memberTypeList: [],
			parkingList: [],
			loading: false,
			user: JSON.parse(localStorage.getItem("currentUser")),
			reasons: "",
			carNo: "",
			approvalDialog: false,
			memberId: 0
		};
	},
	mounted() {
		this.getData();
		this.getMemberInfoTypeList();
		this.getParkingList();
	},
	methods: {
		getData() {
			this.loading = true;
			this.searchForm.page = this.page.current;
			this.searchForm.size = this.page.size;
			if (this.searchForm.startDate != "" && this.searchForm.endDate != "") {
				this.searchForm.startDate = moment(this.searchForm.startDate).format("YYYY-MM-DD");
				this.searchForm.endDate = moment(this.searchForm.endDate).format("YYYY-MM-DD");
			}
			getMemberInfoList(JSON.stringify(this.searchForm)).then(res => {

				this.tableData = res.data.rows;
				this.page.total = res.data.total;
				/*if (this.user.permissions.indexOf('33') != -1){
					this.tableData = res.data.rows.filter(item=>{
						return item.approvalStatus != 0
					})
				}*/
				setTimeout(() => {
					this.loading = false;
				}, 200);
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		addMember() {
			this.showEditMem = true;
			this.state = "新增";
			/*this.rows = {
				carNo:'',
				memberType:'',
				memberTypeId:'',
				startDate:'',
				endDate:'',
				carOwner:'',
				dataHandler:'',
				ownerCardNo:'',
				ownerTel:'',
				ownerCompany:'',
				parkId:'',
			};*/
		},
		// 修改
		handleEdit(index, row) {
			this.state = "编辑";
			this.showEditMem = true;
			// this.rows = JSON.parse(JSON.stringify(row));
			this.memberId = Number(row.id);
		},
		editMemInfo(obj) {
			if (!obj.flg) {
				this.showEditMem = false;
				this.getData();
			}
		},
		getMemberInfoTypeList() {
			this.$get("/car/memberInfoType/list").then(res => {
				this.memberTypeList = res.data.data;
			});
		},
		getParkingList() {
			let params = {
				page: 1,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},
		reloadSearch() {
			this.searchForm = {
				chargeParking: "",
				ownerCompany: "",
				memberType: "",
				startDate: "",
				endDate: ""
			};
			this.page.current = 1;
			this.getData();
		},
		doAddBlack(row) {
			this.blacklist = true;
			this.formBlack.carNo = row.carNo;
			this.formBlack.memberId = row.id;
		},
		addBlack() {
			let params = {
				id: this.formBlack.memberId,
				blackLevel: this.formBlack.blackLevel,
				blackReason: this.formBlack.blackReason
			};
			addToBlack(params).then(res => {
				//移出成功
				if (res.data.code === 200) {
					this.blacklist = false;
					this.getData();
					this.$message.success(res.data.msg);
				} else {
					this.$message.error(res.data.msg ? res.data.msg : "操作失败");

				}
			});
		},

		//提交给主管审核
		toApproval(row, index) {
			let approval = 1;
			//有审核权限的用户直接审核掉
			if (this.user.permissions.indexOf("33") != -1) {
				approval = 2;
			}
			if (this.user.name !== row.createBy || this.user.permissions.indexOf("33") == -1){
			    this.$message.warning("您没有权限提交审核")
                return;
            }
			this.$confirm("是否提交审核?", "提示", { type: "warning" })
					.then(() => {
						this.$put("/car/member-info/edit", {
							id: row.id,
							approvalStatus: approval
						}).then(res => {
							if (res.data.code == 200) {
								this.$message.success("提交成功");
								this.tableData[index].approvalStatus = 1;
							} else {
								this.$message.error(res.data.msg);
							}
						});
					}).catch(() => {
				this.$message.info("已取消操作!");
			});
		},

		submit(state) {
			this.$put("/car/member-info/approval", {
				id: this.memberId,
				approvalStatus: state,
				refuseReasons: this.reasons
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success(res.data.msg);
					this.approvalDialog = false;
					this.getData();
				}else {
					this.$message.error(res.data.msg);
					this.approvalDialog = false;
				}
			});
		},
		approval(row) {
			this.carNo = row.carNo;
			this.memberId = row.id;
			this.approvalDialog = true;
		}
	},
	components: {
		"updateMember": () => import("./updateMember"),
		"importFile": () => import("./importFile")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
